import React, { ReactElement } from "react";
import { Badge } from "react-bootstrap";
import { ClaimStatuses } from "../../libs/apiHelpers/claims";

interface Props {
  status: ClaimStatuses;
}

export default function ClaimStatusBadge(props: Props) {
  switch (props.status) {
    case ClaimStatuses.Open:
      return (
        <Badge className="ml-2" variant="secondary">
          opened
        </Badge>
      );
    case ClaimStatuses.Done:
      return (
        <Badge className="ml-2" variant="secondary">
          closed
        </Badge>
      );
    case ClaimStatuses.Canceled:
      return (
        <Badge className="ml-2" variant="secondary">
          canceled
        </Badge>
      );
    case ClaimStatuses.Payment:
      return (
        <Badge className="ml-2 text-dark" variant="warning">
          waiting for payment
        </Badge>
      );
    case ClaimStatuses.Progress:
      return (
        <Badge className="ml-2 text-dark" variant="warning">
          in progress
        </Badge>
      );

    default:
      return <></>;
  }
}
