import Link from "next/link";
import React, { ReactElement } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import BSIcon from "../BSIcon/BSIcon";

interface Props extends ButtonProps {
  href: string;
}

export default function GoogleLogin({ href, ...props }: Props): ReactElement {
  return (
    <Link prefetch={false} passHref href={href}>
      <Button
        style={{
          backgroundColor: "#4285F4",
          padding: "10px 12px",
          border: "none",
          display: "inline",
          borderRadius: 25,
          ...props?.style
        }}
        {...props}>
        <BSIcon size={16} name="google" />
      </Button>
    </Link>
  );
}
