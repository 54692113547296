import React from "react";
import Link from "next/link";
import { Routes } from "../../libs/constants";

export default function Agreement() {
  return (
    <div>
      <p className="text-muted">
        <small>
          You agree to our{" "}
          <Link prefetch={false} href={Routes.Terms}>
            Terms&nbsp;of&nbsp;usage
          </Link>
          ,{" "}
          <Link prefetch={false} href={Routes.Privacy}>
            Privacy&nbsp;Policy
          </Link>
          ,{" "}
          <Link prefetch={false} href={Routes.Cookie}>
            Cookie&nbsp;Policy
          </Link>{" "}
          and to receive emails from us
        </small>
      </p>
    </div>
  );
}
