import { Formik } from "formik";
import React, { ReactElement, useState } from "react";
import { Row } from "react-bootstrap";
import { mutate } from "swr";
import * as yup from "yup";
import { createClaim, ClaimsListResponse } from "../../../libs/apiHelpers/claims";
import { withApiErrors } from "../../../libs/apiHelpers/errorHandlers";
import { formatDateApi, formatTodayApi, getDaysFromToday } from "../../../libs/helpers/dates";
import { reachGoal } from "../../../libs/helpers/metrics";
import ActivitiesSelect from "../ActivitiesSelect";
import CitySelect from "../CitySelect";
import CurrencyInput from "../CurrencyInput";
import DatePicker from "../DatePicker";
import Form from "../Form";
import MultilineInput from "../MultilineInput";
import SubmitButton from "../SubmitButton";

enum Fields {
  cityId = "city_id",
  deadline = "ends_at",
  details = "details",
  price = "price",
  activities = "activities"
}

interface DefaltValuesInterface {
  defaultCity: { label: string; value: number };
  cityId: number;
  deadline: string;
}

interface Props {
  onSubmit?: (values: DefaltValuesInterface) => void;
  defaultValues?: DefaltValuesInterface;
}

export default function CreateClaim({ onSubmit, defaultValues }: Props): ReactElement {
  const initialValues = {
    [Fields.cityId]: defaultValues?.cityId || undefined,
    [Fields.deadline]: defaultValues?.deadline || "",
    [Fields.details]: "",
    [Fields.price]: "",
    [Fields.activities]: []
  };
  const validationSchema = yup.object({
    [Fields.activities]: yup.array().notRequired(),
    [Fields.cityId]: yup.number().required("You need to select place where you want to do buddy things"),
    [Fields.deadline]: yup
      .string()
      .required("Please provide date until your request need to be done")
      .min(10, "Enter valid date"),
    [Fields.price]: yup.string().required("You need to decide how much you are ready to pay for your need"),
    [Fields.details]: yup
      .string()
      .required("You need to explain your need")
      .max(200, "Try to shorten description of your need.")
  });

  const [cachedCity, cachedCitySet] = useState({ label: undefined, value: undefined });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => {
        return createClaim({
          ...values,
          price: values.price + "00", // crunch for decimal convertation for stripe
          ends_at: formatDateApi(values.ends_at),
          begins_at: formatTodayApi()
        })
          .then((newRequest) => {
            mutate(
              "/claims/opened",
              (claimsListResponse: ClaimsListResponse): ClaimsListResponse => ({
                ...claimsListResponse,
                results: [...claimsListResponse.results, newRequest.data]
              })
            );
            reachGoal("Submit_Create_Claim", {
              price: parseInt(values.price),
              activities: values.activities.map((activity) => activity.type).toString(),
              location: cachedCity.label,
              deadlineDays: getDaysFromToday(values.ends_at),
              requestLength: values.details.length
            });

            onSubmit &&
              onSubmit({
                cityId: values.city_id,
                defaultCity: cachedCity,
                deadline: values.ends_at
              }); // submit callback
          })
          .catch(withApiErrors({ errorTitle: "Creating claim error", fieldsList: Object.keys(values), helpers }));
      }}>
      <Form>
        <CitySelect
          label="Location"
          onChangeCallback={cachedCitySet}
          initialCity={{ label: defaultValues?.defaultCity.label, value: defaultValues?.defaultCity.value }}
          name={Fields.cityId}
        />
        <DatePicker label="Deadline" name={Fields.deadline} />
        <ActivitiesSelect label="Activities" name={Fields.activities} />

        <CurrencyInput
          label="Initial price"
          comment="Not more than 1000$" //. Your final price can differ after dialog with buddy"
          name={Fields.price}
        />

        <MultilineInput
          label="Describe your need"
          name={Fields.details}
          placeholder="Tell in few words what should be done to help you"
        />

        <Row className="justify-content-center">
          <SubmitButton className="text-center" variant="primary" size="lg">
            Publish
          </SubmitButton>
        </Row>
      </Form>
    </Formik>
  );
}
