import { Formik } from "formik";
import Link from "next/link";
import React, { ReactElement, useState } from "react";
import Form from "./Form";
import Input from "./Input";

import * as yup from "yup";
import SubmitButton from "./SubmitButton";
import GoogleLogin from "../LoginButtons/GoogleLogin";
import { Button, Modal } from "react-bootstrap";
import PasswordInput from "./PasswordInput";
import CreateAccount from "./Registration/CreateAccount";
import { tokenObtainLocal } from "../../libs/apiHelpers/auth";
import { withApiErrors } from "../../libs/apiHelpers/errorHandlers";
import { Routes, storageRefererKey } from "../../libs/constants";
import Agreement from "../Agreement/Agreement";
import { getFromSession } from "../../libs/helpers/storage";

enum Fields {
  email = "email",
  password = "password"
}

interface Props {
  withRegistration?: boolean;
  onSubmit?: Function;
  hideOnRegistrationShow?: boolean;
}

export default function Login({ withRegistration, onSubmit, hideOnRegistrationShow }: Props): ReactElement {
  const initialValues = {
    [Fields.email]: "",
    [Fields.password]: ""
  };
  const validationSchema = yup.object({
    [Fields.email]: yup.string().email("Enter a valid email").required("Email is required"),
    [Fields.password]: yup.string().required("Password is required")
  });

  const [showRegistration, showRegistrationSet] = useState(false);
  const registrationShowHandler = () => showRegistrationSet(true);
  const registrationCloseHandler = () => showRegistrationSet(false);

  const googleAuthParams = [
    "client_id=1077208082045-u336toqkg2cv164n4dsjj4sqrsop4bf2.apps.googleusercontent.com",
    "redirect_uri=https://travelbuddy.io/auth.html",
    "response_type=code",
    "state=google-oauth2",
    "scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile+openid+email+profile+https://www.googleapis.com/auth/user.gender.read+https://www.googleapis.com/auth/user.birthday.read"
  ];
  const referer = getFromSession(storageRefererKey) as string;
  if (referer) {
    googleAuthParams.push(`referer=${referer}`);
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => {
        const { email, password } = values;
        return tokenObtainLocal(email, password)
          .then(() => {
            onSubmit && onSubmit();
          })
          .catch(withApiErrors({ errorTitle: "Login error", ignoreRefreshToken: true }));
      }}>
      <Form style={{ display: hideOnRegistrationShow && showRegistration ? "none" : "block" }}>
        <Input name={Fields.email} type="email" placeholder="Email address" />
        <PasswordInput name={Fields.password} placeholder="Password" />
        <Agreement />
        <SubmitButton block variant="primary" submittingCaption="Logging in">
          Log In
        </SubmitButton>
        <div className="text-center mt-2">
          <Link passHref href={Routes.Reset}>
            <a>
              <small>Forgot password?</small>
            </a>
          </Link>
        </div>
        <div className="text-center mt-4">
          {/* TODO: pass referer */}
          <GoogleLogin block href={`https://accounts.google.com/o/oauth2/auth?${googleAuthParams.join("&")}`} />
        </div>
        {withRegistration && (
          <div className="text-center">
            <hr className="my-4" />
            <Button onClick={registrationShowHandler} variant="outline-primary">
              Create new profile
            </Button>
          </div>
        )}
        {/* Registration modal */}
        <Modal show={showRegistration} onHide={registrationCloseHandler}>
          <Modal.Header closeButton>
            <Modal.Title>Create account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateAccount />
          </Modal.Body>
        </Modal>
      </Form>
    </Formik>
  );
}

Login.defaultProps = {
  withRegistration: true
};
