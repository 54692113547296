import { useFormikContext } from "formik";
import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export interface DateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string; // using as id
  comment?: string;
  label?: string;
}

export default function DateInput({ label, comment, name, ...props }: DateInputProps) {
  const { getFieldProps, getFieldMeta, setFieldValue } = useFormikContext();
  const field = getFieldProps(name);
  const meta = getFieldMeta(name);

  // need component because of DatePicker bug
  const CustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <div
      className={`form-control ${!!meta.error ? "is-inavalid" : ""}`}
      ref={ref as any}
      onClick={onClick}
      {...(props as unknown)}>
      {value}
    </div>
  ));

  const dateStr = field.value || "",
    dateParts = dateStr.split(".");

  const today = new Date();
  return (
    <Form.Group className="text-left" controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <DatePicker
        calendarStartDay={1}
        selected={(field.value && new Date(dateParts[2], dateParts[1] - 1, dateParts[0])) || null}
        onChange={(val: Date) => {
          setFieldValue(
            field.name,
            `${("0" + val.getDate()).slice(-2)}.${("0" + (val.getMonth() + 1)).slice(-2)}.${val.getFullYear()}`
          );
        }}
        minDate={new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)}
        maxDate={new Date(today.getFullYear(), today.getMonth() + 2, today.getDate())}
        dateFormat="dd.MM.yyyy"
        withPortal
        customInput={<CustomInput />}
      />
      {comment && <div className="small text-muted">{comment}</div>}
      {meta.touched && meta.error ? (
        <Form.Control.Feedback className="d-block" type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
}
