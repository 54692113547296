import React, { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import IMask from "imask";

export interface CurrencyInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string; // using as id
  comment?: string;
  label?: string;
}

export default function CurrencyInput({ label, comment, name, ...props }: CurrencyInputProps) {
  const { getFieldProps, getFieldMeta, getFieldHelpers } = useFormikContext();
  const field = getFieldProps(name);
  const meta = getFieldMeta(name);
  const helpers = getFieldHelpers(name);

  const currencyInputRef = useRef();
  useEffect(() => {
    const mask = IMask(currencyInputRef.current, {
      mask: "$num",
      lazy: false,
      blocks: {
        num: {
          // nested masks are available!
          mask: Number,
          thousandsSeparator: " ",
          scale: 0
        }
      }
    });
    mask.on("accept", () => {
      helpers.setValue(mask.unmaskedValue);
    });
    return () => {
      mask.destroy();
    };
  }, []);
  return (
    <Form.Group className="text-left" controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        ref={currencyInputRef}
        {...(props as unknown)}
        defaultValue={field.value}
        name={field.name}
        isInvalid={!!meta.error}
      />
      {comment && <div className="small text-muted">{comment}</div>}
      {meta.touched && meta.error ? <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback> : null}
    </Form.Group>
  );
}
