import Link from "next/link";
import React, { ReactElement, useState } from "react";
import { Badge, Button, Card } from "react-bootstrap";
import { ClaimStatuses, OpenClaim } from "../../libs/apiHelpers/claims";
import { Routes } from "../../libs/constants";
import { formatApiDateToInput, formatDateApiToRelativeString } from "../../libs/helpers/dates";
import { formatPrice } from "../../libs/helpers/price";
import { ProfileIconSmall } from "../Profile/ProfileIcon";
import ClaimStatusBadge from "./ClaimStatusBadge";

interface Props {
  claim: OpenClaim;
  isEditable?: boolean;
  isAppliable?: boolean;
}

const headerBgByStatus = {
  [ClaimStatuses.Done]: "bg-success",
  [ClaimStatuses.Canceled]: "bg-dark"
};

export default function ClaimItem({ claim, isEditable, isAppliable }: Props): ReactElement {
  const {
    id,
    status,
    city,
    ends_at,
    details,
    price,
    activities,
    current_user__is_respondent,
    created_at,
    applicant_profile,
    candidate_accepted,
    candidate_count
  } = claim;

  const [isHovered, isHoveredSet] = useState(false);
  return (
    <Card onMouseEnter={() => isHoveredSet(true)} onMouseLeave={() => isHoveredSet(false)} style={{ height: "100%" }}>
      <Card.Header className={`bg-gradient text-white ${headerBgByStatus[status] || "bg-secondary"}`}>
        <ProfileIconSmall className="mr-2" photo={applicant_profile.image_small} /> {applicant_profile.first_name} needs
        help
        {current_user__is_respondent && (
          <Badge className="ml-2" variant="success">
            applied
          </Badge>
        )}
        <ClaimStatusBadge status={status} />
      </Card.Header>
      <Card.Body>
        <h6>{city.display_name}</h6>
        <div>
          {activities.map((activity) => (
            <Badge className="mr-2" variant="primary" key={activity.type}>
              {activity.type}
            </Badge>
          ))}
        </div>
        <span className="text-success">{formatPrice(price)}</span>
        <p className="small text-muted">
          Appliable until: <b>{formatApiDateToInput(ends_at)}</b>
        </p>
        <hr />
        <p>{details}</p>
        <div className="d-lg-none text-center">
          <Link passHref href={`${Routes.Claims}/${id}`}>
            <Button className="mt-3">View</Button>
          </Link>
        </div>
      </Card.Body>
      <Card.Footer className={`d-none d-lg-block ${isHovered ? "bg-primary" : ""}`}>
        {isHovered ? (
          <Link passHref href={`${Routes.Claims}/${id}`}>
            <a className="d-none d-lg-block text-center text-light">View</a>
          </Link>
        ) : isEditable ? (
          <span className="small text-muted">{candidate_count} buddies applied</span>
        ) : (
          <span className="small text-muted">Published {formatDateApiToRelativeString(created_at)} ago</span>
        )}
      </Card.Footer>
    </Card>
  );
}
