import React, { useState } from "react";
import Link from "next/link";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import animateScrollTo from "animated-scroll-to";
import Brand from "../../assets/brand.svg";
import { useCurrentUser } from "../../libs/apiHelpers/profile";
import { Routes } from "../../libs/constants";
import { reachGoal } from "../../libs/helpers/metrics";
import { clearUserSession } from "../../libs/helpers/userSession";
import bannerImage from "../../assets/landing/banner.jpg";
import styles from "./Landing.module.css";
import AuthModal from "../Modals/AuthModal";

export default function AuthBanner() {
  const { user, isLoggedIn } = useCurrentUser();
  const isUserProfilNotCreated = isLoggedIn && !user;

  const onShowMoreClick = () => {
    reachGoal("Click_Landing_Learn_more");
    const landingStart = document.getElementById("how-it-works");
    animateScrollTo(landingStart);
  };

  const [showLoginModal, showLoginModalSet] = useState(false);

  const showLogin = () => showLoginModalSet(true);
  const hideLogin = () => showLoginModalSet(false);

  const authInner = isUserProfilNotCreated ? (
    <div className="px-4">
      <p>Complete your registration first to get full access and start using the service</p>
      <Link href={Routes.Registration}>
        <Button block variant="primary">
          Continue registration
        </Button>
      </Link>
      <Button onClick={clearUserSession} block variant="secondary">
        Logout
      </Button>
    </div>
  ) : (
    <div className="text-center">
      <h5>Trust&nbsp;locals & Enjoy&nbsp;your&nbsp;travel</h5>
      <Button className="mt-2" onClick={showLogin}>
        Join us
      </Button>
    </div>
  );
  return (
    <div className={styles.bannerContainer}>
      <Image src={bannerImage} className={styles.bannerBackgroundImage} alt="" />
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md="6">
            <div>
              <div className="mt-5 mt-md-0 mx-auto mx-md-0 text-center text-md-left">
                <div className="d-inline-block">
                  <Image src={Brand} alt="brand" style={{ width: 300 }} />
                </div>
              </div>
              <h2 className="text-center text-md-left">
                <div>
                  <span className="mb-0 text-light">We connect you with locals, who can help solve your tasks</span>
                  <div className="text-center text-md-left">
                    <Button className="px-0 py-3 py-md-0" variant="link" onClick={onShowMoreClick}>
                      <small className="text-white-50">How it works?</small>
                    </Button>
                  </div>
                </div>
              </h2>
            </div>
          </Col>

          <Col className="d-none d-md-block mt-4 mt-md-0" md="6">
            <Card className="mx-auto" style={{ maxWidth: 350, opacity: 0.9 }}>
              <Card.Body>{authInner}</Card.Body>
            </Card>
          </Col>

          <div className="d-md-none" style={{ height: 200 }}>
            &nbsp;
            <div className={styles.mobileAuthCard}>{authInner}</div>
          </div>
        </Row>
        <AuthModal show={showLoginModal} closeHandler={hideLogin} />
      </Container>
    </div>
  );
}
