// <link rel="preload" href="/api/data" as="fetch" crossorigin="anonymous">
import Head from "next/head";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import CreateClaimMultiply from "../components/Forms/Claims/CreateClaimMultiply";
import PageLayout from "../components/_Layout/PageLayout";
import { useOpenedClaims, useProgressClaims } from "../libs/apiHelpers/claims";
import { useCurrentUser } from "../libs/apiHelpers/profile";
import ClaimItem from "../components/Claim/ClaimItem";
import LandingContents from "../components/Landing/LandingContents";
import { useEffect, useState } from "react";
import BSIcon from "../components/BSIcon/BSIcon";
import { reachGoal } from "../libs/helpers/metrics";
import FullScreenLoader from "../components/Loader/FullScreenLoader";
import AuthBanner from "../components/Landing/AuthBanner";
import { useRouter } from "next/router";
import { getRefererParam } from "../libs/helpers/browser";
import { writeToSession } from "../libs/helpers/storage";
import { storageRefererKey } from "../libs/constants";

export default function Home() {
  const { user, isLoggedIn, isLoading } = useCurrentUser();
  const { claims: openedClaims } = useOpenedClaims();
  const { claims: progressClaims } = useProgressClaims();
  const hasOpenedClaims = openedClaims?.length > 0;
  const hasProgressClaims = progressClaims?.length > 0;

  const [showClaimModal, showClaimModalSet] = useState(false);

  const onDescriptionSubmit = (e) => {
    e.preventDefault();
    showClaimModalSet(true);
  };

  const BannerContents = ({ children }) => {
    const caption = (
      <div className="mt-5 mb-4 text-center">
        <h3>While traveling you can't waste your time</h3>
        <div className="my-3">
          <h5>
            Assign your tasks to a buddy
            <br /> who lives in the place you are visiting
            <br /> and find help from those who have the best solutions
          </h5>
        </div>
      </div>
    );

    return hasOpenedClaims || hasProgressClaims ? (
      <div className="text-center">
        {caption}
        <div className="mx-auto" style={{ width: 300 }}>
          {children}
        </div>
      </div>
    ) : (
      <div className="welcome">
        <div className="welcome-content">
          <div className="welcome-content-group pb-5">
            {caption}
            <div className="mx-auto" style={{ width: 300 }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const router = useRouter();
  useEffect(() => {
    if (!router.isReady) {
      return null;
    }
    const referer = getRefererParam(router);
    if (referer) {
      writeToSession(storageRefererKey, referer);
    }
  }, [router.isReady]);

  return (
    <PageLayout withHeader={!!user}>
      <main>
        <Head>
          <title>Travel Buddy – Help during traveling</title>
        </Head>
        {user ? (
          <div>
            <BannerContents>
              <Form onSubmit={onDescriptionSubmit}>
                <div className="position-relative">
                  <Form.Control
                    onClick={onDescriptionSubmit}
                    value=""
                    onChange={() => {}} // react warning
                    size="lg"
                    name="claim-description"
                    placeholder="Describe your need"
                  />
                  <Button
                    tabIndex={-1}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 8,
                      minWidth: 30,
                      width: 50,
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                    type="submit"
                    variant="link">
                    <BSIcon size={24} name={"arrow-right-square"} />
                  </Button>
                </div>
              </Form>
            </BannerContents>
            <Modal
              show={showClaimModal}
              onHide={() => {
                showClaimModalSet(false);
                reachGoal("Close_Create_Claim");
              }}>
              <Modal.Header closeButton>
                <Modal.Title>Describe your need</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CreateClaimMultiply />
              </Modal.Body>
            </Modal>

            {hasOpenedClaims || hasProgressClaims ? (
              <Container className="mb-4">
                <section>
                  <h2 className="mb-2">Your requests:</h2>
                  {hasProgressClaims && (
                    <Row>
                      {progressClaims.map((claim) => (
                        <Col className="mb-4" xs={12} sm={6} md={4} xl={3} key={claim.id}>
                          <ClaimItem isEditable claim={claim} />
                        </Col>
                      ))}
                    </Row>
                  )}
                  {hasOpenedClaims && <hr className="mb-5" />}
                  {hasOpenedClaims && (
                    <Row>
                      {openedClaims.map((claim) => (
                        <Col className="mb-4" xs={12} sm={6} md={4} xl={3} key={claim.id}>
                          <ClaimItem isEditable claim={claim} />
                        </Col>
                      ))}
                    </Row>
                  )}
                </section>
              </Container>
            ) : (
              <LandingContents />
            )}
          </div>
        ) : isLoading ? (
          <div>
            <FullScreenLoader />
          </div>
        ) : (
          <div>
            <AuthBanner />
            <LandingContents />
          </div>
        )}
      </main>
    </PageLayout>
  );
}
