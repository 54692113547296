import React, { ReactElement, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { reachGoal } from "../../../libs/helpers/metrics";
import BSIcon from "../../BSIcon/BSIcon";
import CreateClaim from "./CreateClaim";

export default function CreateClaimMultiply(): ReactElement {
  const [claimDefaultValues, claimDefaultValuesSet] = useState({
    defaultCity: { label: undefined, value: undefined },
    cityId: undefined,
    deadline: ""
  });

  const [claimCreated, claimCreatedSet] = useState(false);
  const addOneMoreHandler = () => {
    claimCreatedSet(false);
    reachGoal("Click_Create_More_Claim");
  };
  return claimCreated ? (
    <div>
      <Alert variant="success">
        <h4>
          <BSIcon size={32} name="check2-circle" /> Created!
        </h4>
        <p>
          All local buddies will be notified about your request. After someone respondes you will get notification on
          email.
        </p>
        <div className="text-center">
          <Button onClick={addOneMoreHandler}>One more</Button>
        </div>
      </Alert>
    </div>
  ) : (
    <CreateClaim
      onSubmit={(values) => {
        claimCreatedSet(true);
        claimDefaultValuesSet(values);
      }}
      defaultValues={claimDefaultValues}
    />
  );
}
