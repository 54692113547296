import React from "react";
import { Image } from "react-bootstrap";
import logoSvg from "../../assets/logo.svg";
import styles from "./loader.module.css";

export default function FullScreenLoader() {
  return (
    <div className={`${styles["loader-wrapper"]} centered`}>
      <Image width={100} className={styles["loader-image"]} src={logoSvg} />
    </div>
  );
}
