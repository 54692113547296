import React, { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import BSIcon from "../BSIcon/BSIcon";
import Input, { InputProps } from "./Input";
import styles from "../../styles/Forms.module.css";

export default function PasswordInput(props: InputProps): ReactElement {
  const [showHidden, showHiddenSet] = useState(false);
  const toggleHidden = () => showHiddenSet(!showHidden);
  return (
    <div className="position-relative">
      <Button
        tabIndex={-1}
        className={`${styles.passwordShow} text-muted`}
        onClick={toggleHidden}
        type="button"
        variant="link">
        <BSIcon size={14} name={showHidden ? "eye" : "eye-slash"} />
      </Button>
      <Input type={showHidden ? "text" : "password"} {...props} />
    </div>
  );
}
