import { Formik } from "formik";
import React, { ReactElement } from "react";
import { Row } from "react-bootstrap";
import * as yup from "yup";
import { createUser, tokenObtainLocal } from "../../../libs/apiHelpers/auth";
import { withApiErrors } from "../../../libs/apiHelpers/errorHandlers";
import { storageRefererKey } from "../../../libs/constants";
import { reachGoal } from "../../../libs/helpers/metrics";
import { getFromSession } from "../../../libs/helpers/storage";
import Agreement from "../../Agreement/Agreement";
import Form from "../Form";
import Input from "../Input";
import PasswordInput from "../PasswordInput";
import SubmitButton from "../SubmitButton";

enum Fields {
  email = "email",
  password = "password"
}

interface Props {
  onSubmit?: Function;
}

export default function CreateAccount({ onSubmit }: Props): ReactElement {
  const initialValues = {
    [Fields.email]: "",
    [Fields.password]: ""
  };
  const validationSchema = yup.object({
    [Fields.email]: yup.string().email("Enter a valid email").required("Email is required"),
    [Fields.password]: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required")
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => {
        const { email, password } = values;
        const referer = getFromSession(storageRefererKey) as string;
        return createUser(email, password, referer)
          .then(async () => {
            reachGoal("Submit_Create_Account");
            const tokens = await tokenObtainLocal(email, password);
            onSubmit && onSubmit(); // submit callback
          })
          .catch(withApiErrors({ errorTitle: "Registration error", fieldsList: Object.keys(values), helpers }));
      }}>
      <Form>
        <Input name={Fields.email} type="email" placeholder="Email address" />
        <PasswordInput
          comment="Password should be at least 8 characters"
          name={Fields.password}
          placeholder="Password"
        />
        <Agreement />
        <Row className="justify-content-center">
          <SubmitButton variant="primary">Create account</SubmitButton>
        </Row>
      </Form>
    </Formik>
  );
}
