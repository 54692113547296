import React from "react";
import { Modal } from "react-bootstrap";
import Login from "../Forms/Login";

interface AuthModalInterface {
  show: boolean;
  closeHandler: Function;
}

export default function AuthModal({ show, closeHandler }: AuthModalInterface) {
  return (
    <Modal show={show} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Log in</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Login hideOnRegistrationShow={true} />
      </Modal.Body>
    </Modal>
  );
}
